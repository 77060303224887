.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popupContent {
    background: #fff;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    padding: 20px;
    text-align: center;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.popupClose {
    position: absolute;
    top: 0px;
    right: 0px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    padding-left: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-right: 20px;
}

.popupIcon {
    width: 60px;
    height: auto;
    margin-bottom: 15px;
}

.popupBody {
    padding-top: 10px;
    h2 {
        margin: 0 0 10px;
        color: #000;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    p {
        margin: 0;
        color: #000;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}